import { AuthenticationContext, adalGetToken } from "react-adal";
import { KEY } from "@src/constants/common.const";
export const config = {
  // backendEndpoint: "http://localhost:57679/api/",
  // defaultEndpoint: "http://localhost:57679/api/",
  // clientEndpoint: "http://localhost:3000/",
  // clientId: "ce590067-b8a9-4b25-985a-442b9d82be65",

  backendEndpoint: "https://workflowapi-st.shi-ev.com/api/", // Azure
  defaultEndpoint: "https://workflowapi-st.shi-ev.com/api/", // Azure
  clientEndpoint: "https://workflow-st.shi-ev.com/", // Azure
  clientId: "915e60f8-613e-4598-81c2-31794e067116", // Azure

  timeoutDefault: 600000,
};
export const adalConfig = {
  tenant: "adfs",
  clientId: config.clientId,
  redirectUri: config.clientEndpoint,
  postLogoutRedirectUri: config.clientEndpoint,
  instance: "https://evgfs.shi.co.jp/",
  endpoints: {
    api: config.defaultEndpoint,
  },
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);

async function getNewToken() {
  var token = authContext.getCachedToken(adalConfig.clientId);
  if (token === null) {
    token = await adalGetToken(authContext, adalConfig.clientId);
  }
  return token;
}

export const getToken = getNewToken;

export const getUser = () => authContext.getCachedUser();

export const logOut = () => {
  var idToken = localStorage.getItem("adal.idtoken");
  if (idToken !== null && idToken !== "") {
    localStorage.removeItem("displayName");
    localStorage.removeItem(KEY.USER_ROLE);
    authContext.logOut();
  } else {
    localStorage.clear();
    window.location.replace(config.clientEndpoint);
  }
};
